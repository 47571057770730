<template>
  <v-card>
    <v-card-text class="pa-0">
      <template>
        <v-toolbar card color="white">
          <v-text-field
            flat
            solo
            placeholder="Filtrar"
            v-model="search"
            hide-details
            class="hidden-sm-and-down"
          ></v-text-field>
          <v-btn icon @click="pesquisar">
            <v-icon>search</v-icon>
          </v-btn>
          <v-btn icon @click="cadastro">
            <v-icon>add_circle_outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="vendedores"
          :rows-per-page-items="[10, 25, 50, { text: 'All', value: -1 }]"
          class="elevation-1"
        >
          <template v-slot:items="props">
            <td>{{ props.item.id }}</td>
            <td>{{ props.item.nome }}</td>
            <td>{{ props.item.telefone }}</td>
            <td>{{ props.item.cpf }}</td>
            <td>{{ props.item.praca }}</td>
            <td>{{ props.item.situacao }}</td>
            <td>{{ props.item.saldoDevedor }}</td>
            <td class="text-xs-right">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" depressed icon dark v-bind="attrs" v-on="on">
                    <v-icon small>article</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-btn title="Editar" depressed icon dark color="primary" @click="edit(props.item.id)" small>
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn title="Visualizar" depressed icon dark color="primary" @click="view(props.item.id)" small>
                    <v-icon small>remove_red_eye</v-icon>
                  </v-btn>
                  <v-btn title="Unificar" depressed icon dark color="primary" @click="unificar(props.item.id)" small>
                    <v-icon small>compare_arrows</v-icon>
                  </v-btn>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>
<script>
import { ServicoVendedor } from "../../servicos/servicoVendedor"
const servicoVendedor = new ServicoVendedor()
export default {
  data() {
    return {
      search: "",
      vendedores: [],
      vendedoresSemfiltro: [],
      headers: [
        { text: "Código", align: "left", value: "id" },
        { text: "Nome", align: "left", value: "nome" },
        { text: "Telefone", value: "telefone" },
        { text: "CPF", align: "left", value: "cPF" },
        { text: "Praça", align: "left", value: "praca" },
        { text: "Situação", align: "left", value: "situacao" },
        { text: "Débito $", align: "left", value: "saldoDevedor" },
        { text: "", value: "action", align: "right" }
      ]
    }
  },
  created() {
    servicoVendedor.getTableVendedores().then(res => {
      if (res.status === 200) {
        this.vendedores = res.data
        this.vendedoresSemfiltro = this.vendedores
      }
    })
  },
  methods: {
    view(id) {
      this.$router.push({ name: "view-vendedor", params: { id: id } })
    },
    edit(id) {
      this.$router.push({ name: "edit-vendedor", params: { id: id } })
    },
    cadastro() {
      this.$router.push({ name: "cadastro-vendedor" })
    },
    unificar(id) {
      this.$router.push({ name: "unificar-vendedor", params: { id: id } })
    },
    pesquisar() {
      if (this.search === "") {
        this.vendedores = this.vendedoresSemfiltro
      } else {
        this.vendedores = this.vendedoresSemfiltro
        var result = this.vendedores.filter(obj => {
          return (
            (obj.id !== null && obj.id.toString().includes(this.search, 0)) ||
            (obj.nome !== null &&
              obj.nome
                .toString()
                .toUpperCase()
                .includes(this.search.toUpperCase(), 0)) ||
            (obj.cpf !== null && obj.cpf.toString().includes(this.search, 0)) ||
            (obj.praca !== null &&
              obj.praca
                .toString()
                .toUpperCase()
                .includes(this.search.toUpperCase(), 0)) ||
            (obj.situacao !== null &&
              obj.situacao
                .toString()
                .toUpperCase()
                .includes(this.search.toUpperCase(), 0)) ||
            (obj.telefone !== null && obj.telefone.toString().includes(this.search, 0))
          )
        })
        this.vendedores = result
      }
    }
  }
}
</script>
