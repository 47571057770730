<template>
  <div id="pageVendedor">
    <v-container grid-list-xl fluid>
      <v-layout row wrap justify-center>
        <v-flex lg10 sm12 xs12>
          <vendedor-table></vendedor-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import VendedorTable from "@/components/vendedor/tableVendedor"
export default {
  components: {
    VendedorTable
  }
}
</script>
